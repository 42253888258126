import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import 'wicg-inert';

import Portal from '../Portal';
import Button from '../../interface/button';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  & .modal-content {
    transform: translateY(100px);
    transition-property: transform, opacity;
    transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  min-height: 50px;
  min-width: 50px;
  max-height: 80%;
  max-width: 720px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 4px;
  overflow: auto;
  font-family: ${({ theme }) => theme.typography.fontFamily};

  & > h2 {
    font-size: 24px;
    color: ${({ theme }) => theme.palette.primary.dark};
    margin: 0;
  }

  & > h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.palette.primary.dark};
    margin: 0;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;

    & > button {
      width: calc(50% - 1em);
    }
  }
`;

export default function Policy({ open, onDeny, onConfirm, locked }) {
  const [active, setActive] = useState(false);
  const backdrop = useRef(null);

  useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e) => !locked && [27].indexOf(e.which) >= 0 && onDeny();

    const clickHandler = (e) => !locked && e.target === current && onDeny();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
      window.addEventListener('keyup', keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector('#root').setAttribute('inert', 'true');
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }

      document.querySelector('#root').removeAttribute('inert');
      window.removeEventListener('keyup', keyHandler);
    };
  }, [open, locked, onDeny]);

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open && 'active'}>
            <Content className="modal-content">
              <h2>Using your personal information – Rolls-Royce Portal2Power</h2>
              <p>
                Rolls-Royce Plc’s External Marketing & Communications team needs to collect some personal information
                from you on this form so that we can record anyone who has full access to our virtual marketing portal -
                Portal2Power.
              </p>
              <h3>Why we need your data</h3>
              <p>
                The information you give us will be used to provide you with full access to our virtual marketing
                portal. Your data may also be used for purposes of virtually connecting you with Rolls-Royce personnel
                during your visit to the portal. Our External Marketing & Communications team will use your data to
                analyse traffic behaviour whilst in the portal to improve future user experience. Analytical data may
                also be shared internally within Rolls-Royce as part of wider marketing analysis activities.
              </p>
              <h3>What we do with your data</h3>
              <p>
                We will transfer the personal information you give us to a database hosted on a Rolls-Royce secure
                server in the UK. Your name and email address that you provide us with will be held within our securely
                hosted CMS which is accessed only by authorised CMS administrators within Rolls-Royce and the creators
                of the portal itself, our Agency, Bloc Digital Ltd. Appropriate steps will be taken to ensure that your
                data remains secure and is not used for any purpose other than already stated above. We will not
                disclose your data to any other external companies or bodies without your knowledge, unless we are
                required to do so by law. How long we keep your data We will retain your personal data for as long as we
                have a specified, legitimate interest in doing so. This will be reviewed regularly and, if there is no
                longer a legitimate basis for us to retain your data, we will delete it. At that point we will destroy
                your personal information securely and only keep statistical information, such as how many people
                visited the portal in a specific time-frame, dwell points and from which organisations.
              </p>
              <h3>What are your rights?</h3>
              <p>
                Rolls-Royce Plc acknowledges that your personal data is important to you and will process your personal
                data fairly and in accordance with Data Privacy legislation within the regions we operate. To support
                this we have a Data Privacy regime in place to oversee the effective and secure processing of your
                personal data.
              </p>
              <p>
                If at any point you believe the information we process on you is incorrect, you can request to see this
                information and have it corrected or deleted. If you have any concerns or wish to raise a complaint on
                how we have handled your personal data you can contact us to have the matter investigated at{' '}
                <a href="mailto:data.privacy@rolls-royce.com">data.privacy@rolls-royce.com</a>.
              </p>
              <p>
                If you are not satisfied with our response or believe we are not processing your personal data in
                accordance with the law, you can complain to your national data privacy regulator.
              </p>
              <p>
                For further information on how your information is used and more details on your Data Privacy rights
                please visit the data privacy web pages on Engine Room.
              </p>
              <h3>The types of personal information we may collect and use:</h3>
              <p>
                Your full name, organisation/company you work for, your position/job title, your email address, your
                purpose of access requirement (eg interest).
              </p>
              <h3>The types of processes we will use your data for are:</h3>
              <ul>
                <li>Providing you with full access to our Virtual Marketing Portal.</li>
                <li>Inviting you to virtual meetings/events hosted by Rolls-Royce.</li>
                <li>Connecting you with Rolls-Royce personnel, at your request, for follow-on discussions.</li>
                <li>Sharing important information with you about Rolls-Royce products and/or services.</li>
                <li>Marketing analysis reports to monitor visitor activity within the Virtual Marketing Portal.</li>
              </ul>
              <div className="buttons">
                <Button onClick={onDeny} variant="secondary">
                  Decline
                </Button>
                <Button onClick={onConfirm}>Accept</Button>
              </div>
            </Content>
          </Backdrop>
        </Portal>
      )}
    </>
  );
}

import { useMemo, useState, useEffect, useRef } from 'react';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { checkContrast } from '../../interface';
import Button from '../../interface/button';
import { useCookies } from 'react-cookie';
import Portal from '../Portal';

const StyledDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  transform: translateY(100%);
  opacity: 0;
  transition: ${({ theme }) => `all ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeInOut}`};
  color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.common.white)};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 400px;
    border-radius: 0 4px 0 0;
    transform: translateX(-100%);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    left: 2em;
    width: 340px;
    border-radius: 4px 4px 0 0;
    box-shadow: ${({ theme }) => theme.shadows[1]};
  }

  & .cookie-bar__content {
    line-height: 1.2em;
    margin-bottom: 2em;
  }

  & .cookie-bar__buttons {
    display: flex;

    & .spacer {
      flex-grow: 1;
    }

    & .cookie-bar__settings-button {
      color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.common.white)};
      background-color: transparent;
      font-family: ${({ theme }) => theme.typography.body1.fontFamily};
      border: 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    & .button {
      margin: 0;
      flex-shrink: 0;

      &.cookie-bar__accept-button {
      }
    }
  }

  &.internal--visible {
    transform: translateX(0);
    opacity: 1;
    transition-duration: ${({ theme }) => theme.transitions.duration.shortest};
  }
`;

/**
 *
 */
export default function CookieBanner({ theme, className = '', variant, acceptText, hasSettings, children, ...props }) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [classList, setClassList] = useState(['cookie-bar']);

  // handle portal
  const [exists, setExists] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const popup = useRef(null);

  // access cookie records
  const [cookies, setCookie] = useCookies();

  // handle cookie change
  useEffect(() => {
    const { current: el } = popup;
    let timeout;

    const onCookieGone = () => {
      setExists(false);
    };

    if (cookies['cookies_accepted'] === 'true') {
      if (el) el.addEventListener('transitionend', onCookieGone, { once: true });
      setIsShown(false);
    } else {
      setExists(true);
      timeout = window.setTimeout(() => {
        setIsShown(true);
      }, 10);
    }

    return () => {
      window.clearTimeout(timeout);
      if (el) el.removeEventListener('transitionend', onCookieGone);
    };
  }, [cookies]);

  // set up classes/variants
  useEffect(() => {
    const _classList = ['cookie-bar'];
    if (className)
      for (const c of className?.split(' ')) {
        _classList.push(c);
      }

    if (variant)
      for (const v of variant.split(' ')) {
        if (v) _classList.push(`variant--${v}`);
      }

    if (isShown) _classList.push('internal--visible');

    setClassList(_classList);
  }, [className, variant, isShown]);

  return (
    (exists || isShown) && (
      <Portal className="cookie-bar--portal">
        <ThemeProvider theme={_theme}>
          <StyledDiv {...props} className={classList} ref={popup}>
            <div className="cookie-bar__content">{children}</div>
            <div className="cookie-bar__buttons">
              {hasSettings && (
                <button
                  onClick={() => {
                    console.warn("Cookie Settings aren't set up yet, if you need 'em add 'em 🍪");
                  }}
                  className="cookie-bar__settings-button"
                  variant="tertiary"
                >
                  Cookie Settings
                </button>
              )}

              <div className="spacer"></div>

              <Button
                onClick={() => {
                  const date = new Date();
                  date.setDate(date.getDate() + 30);

                  setCookie('cookies_accepted', true, { expires: date });
                }}
                className="cookie-bar__accept-button"
                variant="secondary"
              >
                {acceptText || 'Accept'}
              </Button>
            </div>
          </StyledDiv>
        </ThemeProvider>
      </Portal>
    )
  );
}

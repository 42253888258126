import { useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';

export default function Portal({ active, parent, className, ...props }) {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const target = parent && parent.appendChild ? parent : document.body;
    el.classList.add('portal-container');
    if (className) className.split(' ').forEach((item) => el.classList.add(item));
    target.appendChild(el);

    return () => target.removeChild(el);
  }, [el, parent, className]);

  return createPortal(props.children, el);
}

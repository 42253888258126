import React from 'react';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { Opacity } from './';

const StyleDiv = styled.div`
  display: inline-block;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  width: 180px;

  & > input {
    border-radius: 4px;
    width: 100%;
    font-size: ${({ theme }) => theme.typography.button.fontSize};
    color: ${({ theme }) => theme.palette.common.black};
    background-color: ${({ theme }) => theme.palette.grey[100]};
    line-height: 1em;
    padding: 0.8em 1em;
    box-sizing: border-box;
    border: none;
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};

    &[disabled] {
      background-color: ${({ theme }) => theme.palette.grey[300]};
      opacity: 0.6;
      color: ${({ theme }) => theme.palette.grey[600]};
      pointer-events: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &:focus {
      @supports not (-ms-high-contrast: none) {
        outline: none;
      }
    }
  }

  & > label {
    display: block;
    font-size: 12px;
    margin-bottom: 0.5em;
    text-align: left;
    color: ${({ theme }) => theme.palette.grey[700]};

    & span {
      position: relative;
    }
  }

  &.internal--focused {
    & input {
      border-color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: 0 0 5px ${({ theme }) => Opacity(theme.palette.primary.main, 35)},
        inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
    }

    & label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &.internal--populated {
    & input {
      border-color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
    }

    & label {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.internal--focused {
      & input {
        box-shadow: 0 0 5px ${({ theme }) => Opacity(theme.palette.primary.main, 35)},
          inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  &.internal--invalid {
    & input {
      border-color: ${({ theme }) => theme.palette.error.main};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.error.main};
    }

    label {
      color: ${({ theme }) => theme.palette.error.main};

      & span::after {
        content: '*';
        position: absolute;
        right: -0.75em;
      }
    }

    &.internal--focused {
      & input {
        box-shadow: 0 0 5px ${({ theme }) => Opacity(theme.palette.error.main, 35)},
          inset 0 0 0 1px ${({ theme }) => theme.palette.error.main};
      }
    }
  }

  &.internal--has-icon {
    & > .input__icon {
      pointer-events: none;
      position: absolute;
      bottom: calc(0.7em + 1px);
      left: 0.6em;
      line-height: 0;

      & svg {
        transition-property: fill;
        transition-duration: ${({ theme }) => theme.transitions.duration.shortest};
        transition-timing-function: ${({ theme }) => theme.transitions.easing.easeInOut};
        width: 1.2em;
        height: 1.2em;
        fill: ${({ theme }) => theme.palette.grey[600]};
      }
    }

    & > input {
      padding-left: 2.3em;
    }

    &.internal--focused {
      & > .input__icon {
        & svg {
          fill: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }

    &.internal--invalid {
      & > .input__icon {
        & svg {
          fill: ${({ theme }) => theme.palette.error.main};
        }
      }
    }

    &.variant--icon-right {
      & > .input__icon {
        left: initial;
        right: 0.6em;
      }

      & > input {
        padding-left: 1em;
        padding-right: 2.3em;
      }
    }
  }
`;

/**
 * Material-esc input
 */
const Input = React.forwardRef(
  ({ defaultValue, placeholder, label, className, variant, theme, icon, children, onChange, ...props }, ref) => {
    const _theme = React.useMemo(() => themeModify(theme), [theme]);

    const [classList, setClassList] = React.useState();
    const [isValid, setIsValid] = React.useState(true);
    const [hasFocus, setHasFocus] = React.useState(false);
    const [hasValue, setHasValue] = React.useState(!!defaultValue);

    // set up button classes
    React.useEffect(() => {
      const _classList = ['input'];
      if (className)
        for (const c of className?.split(' ')) {
          _classList.push(c);
        }

      if (variant)
        for (const v of variant.split(' ')) {
          if (v) _classList.push(`variant--${v}`);
        }

      if (!isValid) _classList.push('internal--invalid');
      if (hasFocus) _classList.push('internal--focused');
      if (hasValue) _classList.push('internal--populated');
      if (icon) _classList.push('internal--has-icon');
      if (placeholder) _classList.push('internal--placeholder');

      setClassList(_classList);
    }, [className, hasFocus, hasValue, icon, isValid, placeholder, variant]);

    const blurHandler = React.useCallback((e) => {
      setIsValid(e.target.checkValidity());
      setHasValue(!!e.target.value);
      setHasFocus(false);
    }, []);

    const changeHandler = React.useCallback(
      (e) => {
        setIsValid(true);

        setHasValue(!!e.target.value);
        if (onChange) onChange(e);
      },
      [onChange],
    );

    return (
      <ThemeProvider theme={_theme}>
        <StyleDiv className={classList}>
          {label && (
            <label>
              <span>{label}</span>
            </label>
          )}
          <input
            {...props}
            ref={ref}
            defaultValue={defaultValue}
            onFocus={() => setHasFocus(true)}
            onBlur={blurHandler}
            onChange={changeHandler}
            placeholder={placeholder}
          />
          {icon && <div className="input__icon">{icon}</div>}
        </StyleDiv>
      </ThemeProvider>
    );
  },
);

export default Input;
